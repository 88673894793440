import React, { Suspense, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import VoiceInkLandingPage from './VoiceInkLandingPage';
import { HelmetProvider } from 'react-helmet-async';
import PreviewCard from './components/PreviewCard';

// Lazy load other pages
const PrivacyPolicy = React.lazy(() => import('./PrivacyPolicy'));
const TermsOfService = React.lazy(() => import('./components/TermsOfService'));
const Buy = React.lazy(() => import('./components/Buy'));
const BlogPost = React.lazy(() => import('./components/BlogPost'));

// Analytics component with redirection
const Analytics = () => {
  useEffect(() => {
    window.location.href = 'https://cloud.umami.is/share/CIyBmKcD2n2SW1xD/tryvoiceink.com';
  }, []);
  return null;
};

function App() {
  return (
    <HelmetProvider>
      <Router>
        <Routes>
          <Route path="/" element={<VoiceInkLandingPage />} />
          <Route path="/privacy" element={
            <Suspense fallback={<div className="min-h-screen flex items-center justify-center">Loading...</div>}>
              <PrivacyPolicy />
            </Suspense>
          } />
          <Route path="/terms" element={
            <Suspense fallback={<div className="min-h-screen flex items-center justify-center">Loading...</div>}>
              <TermsOfService />
            </Suspense>
          } />
          <Route path="/buy" element={
            <Suspense fallback={<div className="min-h-screen flex items-center justify-center">Loading...</div>}>
              <Buy />
            </Suspense>
          } />
          <Route path="/blog/:slug" element={
            <Suspense fallback={<div className="min-h-screen flex items-center justify-center">Loading...</div>}>
              <BlogPost />
            </Suspense>
          } />
          <Route path="/analytics" element={<Analytics />} />
          <Route path="/preview" element={<PreviewCard />} />
        </Routes>
      </Router>
    </HelmetProvider>
  );
}

export default App;




