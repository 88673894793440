import React from 'react';
import { Helmet } from 'react-helmet-async';

const MetaTags = () => {
  return (
    <Helmet>
      {/* Primary Meta Tags */}
      <title>VoiceInk - The best dictation app for Mac</title>
      <meta name="title" content="VoiceInk - The best dictation app for Mac" />
      <meta name="description" content="Transform What you say to text instantly with VoiceInk. Advanced AI-powered voice recognition for Mac with offline capability, multiple language support, and privacy focus." />
      
      {/* Open Graph / Facebook */}
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://voiceink.app/" />
      <meta property="og:title" content="VoiceInk - The Best Dictation App for Mac" />
      <meta property="og:description" content="Local AI-powered voice recognition app for Mac. Convert speech to text instantly with offline capability, 100+ languages, and complete privacy." />
      <meta property="og:image" content="https://tryvoiceink.com/preview.png" />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />
      <meta property="og:site_name" content="VoiceInk" />
      <meta property="og:locale" content="en_US" />

      {/* Twitter */}
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:site" content="@Joshipax" />
      <meta property="twitter:creator" content="@Joshipax" />
      <meta property="twitter:title" content="VoiceInk - The best dictation app for Mac" />
      <meta property="twitter:description" content="Transform your voice into text instantly with offline AI. Built for Mac, optimized for privacy." />
      <meta name="twitter:image" content="https://tryvoiceink.com/preview.png" />
      <meta name="twitter:image:alt" content="VoiceInk - Transform Speech to Text Instantly on Mac" />

      {/* Additional SEO tags */}
      <meta name="keywords" content="voice to text, speech recognition, mac app, offline voice recognition, AI transcription, voice typing, dictation software, macOS voice recognition, Apple Silicon optimized, local speech recognition, privacy-focused voice typing, multilingual voice recognition" />
      <meta name="author" content="VoiceInk" />
      <meta name="robots" content="index, follow" />
      <link rel="canonical" href="https://voiceink.app/" />

      {/* Language and locale */}
      <meta property="og:locale" content="en_US" />
      <html lang="en" />

      {/* Additional SEO meta tags */}
      <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=5" />
      <meta name="theme-color" content="#000000" />
      <meta name="application-name" content="VoiceInk" />
      <meta name="apple-mobile-web-app-capable" content="yes" />
      <meta name="apple-mobile-web-app-status-bar-style" content="black" />
      <meta name="apple-mobile-web-app-title" content="VoiceInk" />
    </Helmet>
  );
};

export default MetaTags; 