import React from 'react';
import { Link as ScrollLink } from 'react-scroll';
import { ArrowRight, Sparkles } from 'lucide-react';

const Hero = () => {
  return (
    <section className="flex items-center justify-center py-12">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="text-center max-w-4xl mx-auto">
          {/* Badge */}
          <div className="inline-flex items-center gap-2 bg-gradient-to-r from-purple-500/10 to-blue-500/10 
            backdrop-blur-sm border border-purple-500/20 rounded-full px-4 py-2 mb-8 animate-fade-in">
            <Sparkles className="w-4 h-4 text-purple-400" />
            <span className="text-purple-400 font-medium">POWERED BY WHISPER AI</span>
          </div>

          {/* Main Headline */}
          <h1 className="text-5xl md:text-7xl font-black mb-6 tracking-tight">
            <span className="block text-white mb-2">WRITE AT THE</span>
            <span className="bg-gradient-to-r from-purple-400 via-blue-400 to-purple-400 text-transparent bg-clip-text 
              animate-gradient bg-300% font-black">
              SPEED OF THOUGHT
            </span>
          </h1>

          {/* Compelling Subheadline */}
          <p className="text-xl md:text-2xl text-gray-300 mb-12 max-w-3xl mx-auto leading-relaxed whitespace-normal overflow-visible">
            VoiceInk uses
            <span className="text-white font-semibold"> local AI models </span>
            to instantly transcribe what you say with near-perfect accuracy and 
            <span className="text-white font-semibold"> complete privacy</span>.
          </p>

          {/* Social Proof */}
          <div className="flex items-center justify-center gap-8 mb-12">
            <div className="text-center group transition-all duration-300 hover:scale-105">
              <div className="text-4xl font-black text-white mb-1">5X</div>
              <div className="text-sm font-medium text-gray-400 group-hover:text-white transition-colors">FASTER THAN TYPING</div>
            </div>
            <div className="h-12 w-px bg-white/10" />
            <div className="text-center group transition-all duration-300 hover:scale-105">
              <div className="text-4xl font-black text-white mb-1">100+</div>
              <div className="text-sm font-medium text-gray-400 group-hover:text-white transition-colors">LANGUAGES</div>
            </div>
            <div className="h-12 w-px bg-white/10" />
            <div className="text-center group transition-all duration-300 hover:scale-105">
              <div className="text-4xl font-black text-white mb-1">100%</div>
              <div className="text-sm font-medium text-gray-400 group-hover:text-white transition-colors">PRIVACY</div>
            </div>
          </div>

          {/* CTA Buttons */}
          <div className="flex flex-col sm:flex-row items-center justify-center gap-4">
            <ScrollLink to="pricing" smooth={true} duration={500} className="w-full sm:w-auto">
              <button className="w-full sm:w-auto px-10 h-14 bg-white/90
                hover:bg-white rounded-xl font-bold text-lg text-[#030014]
                transition-all duration-300 flex items-center justify-center gap-2 group
                hover:scale-105"
              >
                TRY VOICEINK FREE
                <ArrowRight className="w-5 h-5 group-hover:translate-x-1 transition-transform" />
              </button>
            </ScrollLink>
            
            <ScrollLink to="how-it-works" smooth={true} duration={500} className="w-full sm:w-auto">
              <button className="w-full sm:w-auto h-14 px-8 rounded-xl font-bold text-lg
                bg-white/5 hover:bg-white/10 backdrop-blur-sm border border-white/10
                text-white transition-all duration-300 flex items-center justify-center gap-2
                hover:border-white/20 hover:scale-105"
              >
                WATCH DEMO
              </button>
            </ScrollLink>
          </div>

          {/* Social Proof with Avatars */}
          <div className="mt-12 flex flex-col items-center">
            <div className="flex items-center gap-1 text-yellow-400 mb-3 text-lg">
              ★★★★★
            </div>
            
            <div className="flex items-center justify-center">
              <div className="flex -space-x-3">
                <div className="relative w-10 h-10 rounded-full ring-4 ring-[#030014] transition-transform hover:scale-105">
                  <img
                    src="https://yt3.ggpht.com/ytc/AIdro_mGGhXHzQ5kg3cgWQ0wHrN19u3c_-i6x6ZUsrnshgayEQpQ=s88-c-k-c0x00ffffff-no-rj"
                    alt="JoseIndie"
                    className="w-full h-full rounded-full object-cover"
                  />
                  <div className="absolute inset-0 rounded-full bg-gradient-to-r from-purple-500/20 to-blue-500/20 opacity-0 hover:opacity-100 transition-opacity duration-300" />
                </div>
                <div className="relative w-10 h-10 rounded-full ring-4 ring-[#030014] transition-transform hover:scale-105">
                  <img
                    src="https://lh3.googleusercontent.com/a-/ALV-UjUaEPzphF3AGS-SgxyaKTPdO3oPuqXxrOdjvKwOobFIeyAqIn-r=s128-p"
                    alt="Artawower LH"
                    className="w-full h-full rounded-full object-cover"
                  />
                  <div className="absolute inset-0 rounded-full bg-gradient-to-r from-purple-500/20 to-blue-500/20 opacity-0 hover:opacity-100 transition-opacity duration-300" />
                </div>
                <div className="relative w-10 h-10 rounded-full ring-4 ring-[#030014] transition-transform hover:scale-105">
                  <img
                    src="https://lh3.googleusercontent.com/a-/ALV-UjVpIp7hfcofEZamjcHX0otFB_k3c-K0yXKd8z4eWWxOZ966rydikCV7rg=s128-p"
                    alt="Gus Silber"
                    className="w-full h-full rounded-full object-cover"
                  />
                  <div className="absolute inset-0 rounded-full bg-gradient-to-r from-purple-500/20 to-blue-500/20 opacity-0 hover:opacity-100 transition-opacity duration-300" />
                </div>
                <div className="relative w-10 h-10 rounded-full ring-4 ring-[#030014] transition-transform hover:scale-105">
                  <img
                    src="https://lh3.googleusercontent.com/a-/ALV-UjVkUw1AFb3XBI9GETaDuMGDTdLeNHLy601FYihSCC-QDaA3tTY=s120-p"
                    alt="Jeff Griebwog"
                    className="w-full h-full rounded-full object-cover"
                  />
                  <div className="absolute inset-0 rounded-full bg-gradient-to-r from-purple-500/20 to-blue-500/20 opacity-0 hover:opacity-100 transition-opacity duration-300" />
                </div>
                <div className="relative w-10 h-10 rounded-full bg-gradient-to-r from-purple-500/20 to-blue-500/20 backdrop-blur-sm border border-white/10 ring-4 ring-[#030014] flex items-center justify-center text-white font-semibold transition-transform hover:scale-105">
                  +50
                </div>
              </div>
              <span className="ml-4 text-sm text-gray-400">
                Loved by users worldwide
              </span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero; 