import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';
import { Menu, X, Twitter, Github } from 'lucide-react';

interface LayoutProps {
  children: React.ReactNode;
}

const Layout = ({ children }: LayoutProps) => {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const location = useLocation();
  const isLandingPage = location.pathname === '/';

  const navLinks = [
    { name: 'AI Modes', to: 'ai-modes' },
    { name: 'How it Works', to: 'how-it-works' },
    { name: 'Testimonials', to: 'testimonials' },
  ];

  return (
    <div className="relative min-h-screen bg-[#030014] flex flex-col">
      {/* Modern grid background */}
      <div className="fixed inset-0 pointer-events-none">
        {/* Gradient background */}
        <div className="absolute inset-0 bg-gradient-to-b from-[#0E0725] via-[#0A0118] to-[#030014]" />
        
        {/* Grid pattern */}
        <div className="absolute inset-0" 
          style={{
            backgroundImage: `
              radial-gradient(circle at 1px 1px, rgba(255, 255, 255, 0.05) 1px, transparent 0),
              linear-gradient(rgba(255, 255, 255, 0.03) 1px, transparent 1px),
              linear-gradient(90deg, rgba(255, 255, 255, 0.03) 1px, transparent 1px)
            `,
            backgroundSize: '32px 32px, 64px 64px, 64px 64px',
            backgroundPosition: '0 0, 0 0, 0 0'
          }} 
        />

        {/* Ambient light effects */}
        <div className="absolute top-0 left-1/4 w-96 h-96 bg-purple-500/10 rounded-full blur-3xl" />
        <div className="absolute bottom-0 right-1/4 w-96 h-96 bg-blue-500/10 rounded-full blur-3xl" />
      </div>
      
      {/* Header */}
      <header className="fixed top-0 left-0 right-0 z-50 h-16 md:h-20">
        {/* Backdrop blur and gradient background */}
        <div className="absolute inset-0 bg-[#030014]/80 backdrop-blur-xl border-b border-white/[0.05]" />
        
        <div className="relative max-w-7xl mx-auto h-full px-4 md:px-6 flex items-center justify-between">
          {/* Logo */}
          <Link to="/" className="flex items-center gap-3 group">
            <div className="relative">
              <div className="absolute -inset-[2px] rounded-xl bg-gradient-to-r from-purple-500/20 to-blue-500/20 blur-sm opacity-0 group-hover:opacity-100 transition-all duration-500" />
              <div className="relative bg-white/[0.05] border border-white/[0.05] rounded-xl p-1 backdrop-blur-sm transition-all duration-300 group-hover:border-white/[0.1]">
                <img src="/appIcon.png" alt="VoiceInk Logo" 
                  className="w-8 h-8 md:w-10 md:h-10 rounded-lg opacity-90 group-hover:opacity-100 transition-opacity" />
              </div>
            </div>
            <span className="text-xl md:text-2xl font-bold bg-gradient-to-r from-white to-white/80 bg-clip-text text-transparent">
              VoiceInk
            </span>
          </Link>

          {/* Desktop Navigation */}
          <nav className="hidden md:flex items-center gap-8">
            {isLandingPage && navLinks.map((link) => (
              <ScrollLink
                key={link.name}
                to={link.to}
                smooth={true}
                duration={500}
                className="relative text-sm font-medium text-gray-300 hover:text-white transition-colors cursor-pointer group"
              >
                {link.name}
                <div className="absolute -bottom-1 left-0 w-0 h-[2px] bg-gradient-to-r from-purple-400 to-blue-400 group-hover:w-full transition-all duration-300" />
              </ScrollLink>
            ))}
          </nav>

          {/* Desktop Actions */}
          <div className="hidden md:flex items-center gap-6">
            <Link to="/buy" 
              className="relative text-sm font-medium text-gray-300 hover:text-white transition-colors group">
              Buy Now
              <div className="absolute -bottom-1 left-0 w-0 h-[2px] bg-gradient-to-r from-purple-400 to-blue-400 group-hover:w-full transition-all duration-300" />
            </Link>
            {isLandingPage && (
              <ScrollLink
                to="pricing"
                smooth={true}
                duration={500}
                className="relative px-6 h-10 flex items-center justify-center group"
              >
                <div className="absolute inset-0 bg-gradient-to-r from-purple-400 via-blue-400 to-purple-400 rounded-xl opacity-0 group-hover:opacity-100 blur transition-all duration-500" />
                <div className="absolute inset-0 bg-white/90 hover:bg-white rounded-xl transition-colors duration-300" />
                <span className="relative font-bold text-sm text-[#030014]">
                  Download
                </span>
              </ScrollLink>
            )}
          </div>

          {/* Mobile Menu Button */}
          <button 
            onClick={() => setIsMenuOpen(!isMenuOpen)}
            className="relative md:hidden p-2 text-gray-300 hover:text-white transition-colors"
          >
            <div className="absolute inset-0 bg-white/5 rounded-lg opacity-0 hover:opacity-100 transition-opacity duration-300" />
            {isMenuOpen ? <X className="relative w-6 h-6" /> : <Menu className="relative w-6 h-6" />}
          </button>
        </div>
      </header>

      {/* Mobile Menu */}
      <div className={`
        fixed inset-0 z-40 bg-[#030014]/95 backdrop-blur-lg transform transition-transform duration-300 ease-in-out
        ${isMenuOpen ? 'translate-x-0' : 'translate-x-full'}
        md:hidden pt-20
      `}>
        <nav className="flex flex-col space-y-6 p-6">
          {isLandingPage && navLinks.map((item) => (
            <ScrollLink 
              key={item.name} 
              to={item.to} 
              smooth={true} 
              duration={500} 
              onClick={() => setIsMenuOpen(false)}
              className="text-lg font-medium text-gray-300 hover:text-white transition-colors"
            >
              {item.name}
            </ScrollLink>
          ))}
          <Link 
            to="/buy" 
            onClick={() => setIsMenuOpen(false)}
            className="text-lg font-medium text-gray-300 hover:text-white transition-colors"
          >
            Buy Now
          </Link>
        </nav>
      </div>

      {/* Main Content */}
      <div className="relative pt-16 md:pt-20 flex-grow">
        {children}
      </div>

      {/* Footer */}
      <footer className="relative mt-12 border-t border-white/5">
        <div className="max-w-7xl mx-auto px-4 md:px-6 py-8">
          <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
            {/* Brand */}
            <div className="space-y-4">
              <div className="flex items-center gap-2">
                <img
                  src="/appIcon.png"
                  alt="VoiceInk Logo"
                  width={32}
                  height={32}
                  className="opacity-90 hover:opacity-100 transition-opacity"
                />
                <span className="text-white/90 hover:text-white font-bold text-xl transition-colors">
                  VoiceInk
                </span>
              </div>
              <p className="text-sm text-gray-400">
                Transform your voice into text instantly with local AI processing.
              </p>
              <div className="flex items-center space-x-4">
                <a href="https://twitter.com/joshipax" target="_blank" rel="noopener noreferrer"
                  className="text-gray-400 hover:text-white transition-colors">
                  <Twitter className="w-5 h-5" />
                </a>
                <a href="https://github.com/beingpax" target="_blank" rel="noopener noreferrer"
                  className="text-gray-400 hover:text-white transition-colors">
                  <Github className="w-5 h-5" />
                </a>
              </div>
            </div>

            {/* Product */}
            <div>
              <h3 className="text-white font-semibold mb-4">Product</h3>
              <ul className="space-y-3">
                <li>
                  <ScrollLink to="pricing" smooth={true} duration={500}
                    className="text-gray-400 hover:text-white transition-colors cursor-pointer">
                    Pricing
                  </ScrollLink>
                </li>
                <li>
                  <Link to="/buy" className="text-gray-400 hover:text-white transition-colors">
                    Buy Now
                  </Link>
                </li>
              </ul>
            </div>

            {/* Resources */}
            <div>
              <h3 className="text-white font-semibold mb-4">Resources</h3>
              <ul className="space-y-3">
                <li>
                  <Link to="/privacy" className="text-gray-400 hover:text-white transition-colors">
                    Privacy Policy
                  </Link>
                </li>
                <li>
                  <Link to="/terms" className="text-gray-400 hover:text-white transition-colors">
                    Terms of Service
                  </Link>
                </li>
              </ul>
            </div>

            {/* Contact */}
            <div>
              <h3 className="text-white font-semibold mb-4">Contact</h3>
              <ul className="space-y-3">
                <li>
                  <a href="mailto:prakashjoshipax@gmail.com" 
                    className="text-gray-400 hover:text-white transition-colors">
                    Email Support
                  </a>
                </li>
                <li>
                  <a href="https://twitter.com/joshipax" target="_blank" rel="noopener noreferrer"
                    className="text-gray-400 hover:text-white transition-colors">
                    Twitter
                  </a>
                </li>
              </ul>
            </div>
          </div>

          {/* Bottom */}
          <div className="mt-12 pt-8 border-t border-white/5">
            <div className="text-center text-sm text-gray-400">
              © {new Date().getFullYear()} VoiceInk. All rights reserved.
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Layout; 