import React from 'react';

export const PreviewCard = () => {
  return (
    <div className="max-w-[1200px] h-[630px] bg-gradient-to-br from-[#030014] to-[#0D0D1D] flex items-center justify-center p-16">
      <div className="flex items-center gap-12">
        <img 
          src="/appIcon.png" 
          alt="VoiceInk Logo" 
          className="w-48 h-48 rounded-3xl shadow-2xl"
        />
        <div className="space-y-6">
          <h1 className="text-7xl font-bold text-white">
            Voice<span className="bg-gradient-to-r from-blue-400 to-purple-400 bg-clip-text text-transparent">Ink</span>
          </h1>
          <p className="text-3xl text-gray-300">
            Transform Speech to Text Instantly on Mac
          </p>
          <div className="flex items-center gap-4">
            <div className="px-4 py-2 bg-blue-500/20 rounded-full border border-blue-500/30">
              <span className="text-xl text-blue-400">Offline AI</span>
            </div>
            <div className="px-4 py-2 bg-purple-500/20 rounded-full border border-purple-500/30">
              <span className="text-xl text-purple-400">100+ Languages</span>
            </div>
            <div className="px-4 py-2 bg-green-500/20 rounded-full border border-green-500/30">
              <span className="text-xl text-green-400">Privacy Focused</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PreviewCard; 