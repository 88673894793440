import React, { Suspense } from 'react';
import Layout from './components/landing/Layout';
import Hero from './components/Hero';
import MetaTags from './components/landing/MetaTags';

// Lazy load other components
const AIModes = React.lazy(() => import('./components/landing/AIModes'));
const HowItWorks = React.lazy(() => import('./components/landing/HowItWorks'));
const Testimonials = React.lazy(() => import('./components/Testimonials'));
const Pricing = React.lazy(() => import('./components/landing/Pricing'));
const FAQ = React.lazy(() => import('./components/landing/FAQ'));

export default function VoiceInkLandingPage() {
  return (
    <Layout>
      <MetaTags />
      <Hero />
      <Suspense fallback={<div className="min-h-[400px] flex items-center justify-center">Loading...</div>}>
        <AIModes />
      </Suspense>
      <Suspense fallback={<div className="min-h-[400px] flex items-center justify-center">Loading...</div>}>
        <Testimonials />
      </Suspense>
      <Suspense fallback={<div className="min-h-[400px] flex items-center justify-center">Loading...</div>}>
        <HowItWorks />
      </Suspense>
      <Suspense fallback={<div className="min-h-[400px] flex items-center justify-center">Loading...</div>}>
        <Pricing />
      </Suspense>
      <Suspense fallback={<div className="min-h-[400px] flex items-center justify-center">Loading...</div>}>
        <FAQ />
      </Suspense>
    </Layout>
  );
}
